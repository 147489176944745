/**
 * 网络请求配置
 */
import axios from "axios";
import { message } from 'antd';
import Qs from 'qs';
let qs = Qs;

// 创建axios实例对象
let instance = axios.create({
  baseURL:"https://youpindou.com:8062",
  timeout: 10000
})
 
// 请求拦截器
instance.interceptors.request.use(config => {
  if(config.url !== '登录接口'){
    config.headers.Authorization = sessionStorage.getItem('token') || ''
  } 
  return config
},error=>{
  alert('请求失败');
  msag(error);
  return Promise.reject(error)
})
 
// 响应拦截器
instance.interceptors.response.use(response=>{
  let res = response.data
  return res
},error=>{
  return Promise.reject(error)
})
 
// 封装get方法并导出
export function get(url,params){
  return instance.get(url,{ params });
}

// 封装postJSON方法 (发送json格式数据)
export function postJson(url,data){
  return instance.post(url,data)
}

// 封装post方法 (发送表单格式数据)
export function post(url,data){
  return instance.post(url,qs.stringify(data))
}

//失败提示
function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        message.error(err.response.data.error.details);
        break;
      case 401:
        message.error("未授权，请登录");
        break;

      case 403:
        message.error("拒绝访问");
        break;

      case 404:
        message.error("请求地址出错");
        break;

      case 408:
        message.error("请求超时");
        break;

      case 500:
        message.error("服务器内部错误");
        break;

      case 501:
        message.error("服务未实现");
        break;

      case 502:
        message.error("网关错误");
        break;

      case 503:
        message.error("服务不可用");
        break;

      case 504:
        message.error("网关超时");
        break;

      case 505:
        message.error("HTTP版本不受支持");
        break;
      default:
    }
  }
}

// 导出axios实例
export default instance

