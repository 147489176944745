import React, { useState, useEffect } from 'react';
import './index.css';
import { Navigate } from 'react-router-dom';
import { Input, Tabs, Form, Button, DatePicker, message } from 'antd';
import { product_publish } from '../../service/api';
import Upload from '../../components/upload';
import UploadList from '../../components/uploadList';

const { RangePicker } = DatePicker;

const tabsList = [
  {
    key: 2,
    label: '创意水杯',
  },
  {
    key: 3,
    label: '服装鞋包',
  },
  {
    key: 4,
    label: '家居百货',
  },
  {
    key: 5,
    label: '家居家纺',
  },
  {
    key: 6,
    label: '美妆个护',
  },
  {
    key: 7,
    label: '日化清洁',
  },
  {
    key: 8,
    label: '数码家电',
  },
  {
    key: 9,
    label: '文玩办公',
  },
  {
    key: 10,
    label: '饰品配件',
  },
  {
    key: 11,
    label: '母婴亲子',
  },
]

const PublishMessage = () => {
  const [tabKey, setTabKey] = useState(2);

  const [iconImg, setIconImg] = useState('');
  const [iconImgLoading, setIconImgLoading] = useState('');

  const [detailsImgs, setDetailsImgs] = useState([]);
  const [detailsImgsModal, setDetailsImgsModal] = useState(false);
  const [detailsPreviewImage, setDetailsPreviewImage] = useState('');

  const [form] = Form.useForm();
  
  const onTabChange = (key) => {
    setTabKey(key);
  };

  //缩略图 图片上传
  const uploadIconImg = (info) => {
    if (info.file.status == 'uploading') {
      setIconImgLoading(true);
    } else {
      setIconImgLoading(false);
    }
    if (info.file.status == 'done') {
      const { data } = info.file.response;
      setIconImgLoading(false);
      setIconImg(data);
    }
  };

  //打开详情页预览 弹窗
  const onDetailsImgOpen = async (file) => {
    setDetailsPreviewImage(file.url || file.response.data);
    setDetailsImgsModal(true);
  };

  //关闭详情页预览 弹窗
  const onDetailsImgCancel = () => {
    setDetailsImgsModal(false);
  };

  //详情页 上传图片
  const uploadDetails = ({ fileList: newFileList }) => setDetailsImgs(newFileList);

  //提交
  const onFinish = (values) => {
    console.log('Success:', values);

    let userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || '';
    console.log(userInfo);

    if(iconImg == '') {
      message.error('请上传缩略图');
      return;
    }

    let detailsImgList = [];

    if(detailsImgs.length > 0) {
      detailsImgs.map(item => {
        if(item.response) {
          detailsImgList.push(item.response.data)
        } else {
          detailsImgList.push(item.url);
        }
      });
    } else {
      message.error('请上传详情页图片');
      return;
    };

    delete values.time;

    const param = {
      ...values,
      detail: detailsImgList.join(','),
      specsImage: iconImg,
      thumbnail: iconImg,
      type: tabKey,
      userId: userInfo.id
    }

    product_publish(param).then(res => {
      if(res.code ==200 && res.isSuccess) {
        message.success('发布成功');
        oncancel();
      }
    });

  };

  //关闭弹窗
  const oncancel = () => {
    form.resetFields();
    setIconImg('');
    setDetailsImgs([]);
  };

  return (
    <div className='container-publishMessage'>
      <header>
        <img src={require('../../assets/logo.png')} alt="" />
        <div>发布消息</div>
      </header>
      <section>
        <div className='sect-title'>请选择发布消息类别</div>
        <div className='sect-tabs'>
          <Tabs defaultActiveKey="2" items={tabsList} onChange={onTabChange} indicatorSize={20} />
        </div>
        <div className='sect-title'>请填写商品详细信息</div>
        <div className='sect-form' style={{ marginBottom: '32px' }}>

          <Form
            name="form-publishMessage"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <div className='form-name'>
              <Form.Item name="name" label="商品名称" rules={[{ required: true }]} >
                <Input placeholder='请输入商品名称' />
              </Form.Item>
            </div>

            <div className='form-content'>
              <div>
                <Form.Item name="business" label="商家" rules={[{ required: true, message: '请输入商家!' }]} >
                  <Input placeholder='请输入商家' />
                </Form.Item>
              </div>
              <div>
                <Form.Item name="contacts" label="联系人" rules={[{ required: true, message: '请输入联系人姓名!' }]} >
                  <Input placeholder='请输入联系人姓名' />
                </Form.Item>
              </div>
              <div>
                <Form.Item name="phone" label="电话" rules={[{ required: true, message: '请输入联系电话!' }]} >
                  <Input placeholder='请输入联系电话' />
                </Form.Item>
              </div>
            </div>
            
            <div className='form-content'>
              <div>
                <Form.Item name="minPrice" label="价格" rules={[{ required: true, message: '请输入最低价格!' }]} >
                  <Input placeholder='最低价' />
                </Form.Item>
                <div className='zhi'>至</div>
                <Form.Item name="maxPrice" label="" rules={[{ required: true, message: '请输入最高价格!' }]} >
                  <Input placeholder='最高价' />
                </Form.Item>
              </div>
              <div>
                <Form.Item name="time" label="时间" rules={[{ required: true, message: '请选择时间!' }]} >
                  <RangePicker />
                </Form.Item>
              </div>
              <div></div>
            </div>

            <div className='form-content'>
              <div>
                <Form.Item label="缩略图">
                  <Upload handleChange={uploadIconImg} imageUrl={iconImg} loading={iconImgLoading} />
                </Form.Item>
              </div>
              <div></div>
              <div></div>
            </div>

            <div>
              <Form.Item label="详情页">
                <UploadList  
                  fileList={detailsImgs}  
                  handleChange={uploadDetails} 
                  previewOpen={detailsImgsModal} 
                  handleCancel={onDetailsImgCancel} 
                  handlePreview={onDetailsImgOpen}
                  previewImage={detailsPreviewImage}
                />
              </Form.Item>
            </div>

            <div className='form-btn'>
              <Button type="primary" htmlType="submit"> 发布 </Button>
            </div>
          </Form>
        </div>
        <div className='filing-number'>
          <div>Copyright @ 2022-2023 杭州倪王科技有限公司 All Right Reserved</div>
          <div>浙ICP备2023008165号-3</div>
        </div>
      </section>
    </div>
  )
};

function AuthRouter() {
  // 获取token
  let token = sessionStorage.getItem('token') || '';
  // 判断token是否存在 存在直接渲染主页面
  if (token) {
      return <PublishMessage />
  } else {
      return <Navigate to={'/login'}></Navigate>  //没有token跳转登录页面
  }
}

export default AuthRouter;
