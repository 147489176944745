import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { Input, Tabs, message } from 'antd';
import { Search, EditTwo, Local } from '@icon-park/react';
import { product_findByPage } from '../../service/api';
import _ from 'loadsh';

const tabsList = [
  {
    key: 1,
    label: '首页',
  },
  {
    key: 2,
    label: '创意水杯',
  },
  {
    key: 3,
    label: '服装鞋包',
  },
  {
    key: 4,
    label: '家居百货',
  },
  {
    key: 5,
    label: '家居家纺',
  },
  {
    key: 6,
    label: '美妆个护',
  },
  {
    key: 7,
    label: '日化清洁',
  },
  {
    key: 8,
    label: '数码家电',
  },
  {
    key: 9,
    label: '文玩办公',
  },
  {
    key: 10,
    label: '饰品配件',
  },
  {
    key: 11,
    label: '母婴亲子',
  },
]

const Home = () => {
  const [tabKey, setTabKey] = useState(1);
  const [userInfo, setUserInfo] = useState({});
  const [inputValue, setInputValue] = useState('');

  const [pageCurrent, setPageCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [goodsList, setGoodsList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('userInfo')) || {};
    setUserInfo(user);
  }, []);

  useEffect(() => {
    getGoodsList();
  }, [pageCurrent, tabKey, inputValue]);

  //获取商品列表
  const getGoodsList = () => {
    let user = JSON.parse(sessionStorage.getItem('userInfo')) || {};

    const param = {
      page: { current: pageCurrent, size: 40 },
      query: { type: tabKey, name: inputValue }
    };

    if(tabKey == 1) delete param.query.type;

    product_findByPage(param).then(res => {
      if(res.code ==200 && res.isSuccess) {
        const { records, total } = res.data;
        if(records.length > 0) {
          if(pageCurrent > 1) {
            let list =  _.uniqWith([...goodsList, ...records], _.isEqual);
            setGoodsList(list);
            // message.warning(`${pageCurrent} ${list.length}-${total}`);
            
          } else {
            setGoodsList(records);
          }
        }
        if(pageCurrent == 1 && records.length == 0) {
          setGoodsList([]);
        }
        setTotal(total);
      }
    });
  };

  // 上拉加载
  const handleScroll = (e) => {
    let current = pageCurrent;
    if(e.target.scrollTop > 200) {
      sessionStorage.setItem('scrollTop', e.target.scrollTop);
    }
    
    //判断滑动到了底部
    if (e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <= 50) {
      //判断是否还需调用接口
      if (goodsList.length != total) {
        setPageCurrent(current+1);
        // message.warning(`${current+1} ${goodsList.length}-${total}`);
      }
    }
  };

  //搜索
  const handleSearch = e => {
    setInputValue(e.target.value);
  };

  const onTabChange = (key) => {
    setTabKey(key);
    setPageCurrent(1);
  };

  const goGoodsDetail = (values) => {
    navigate('/goods-detail', { state: { details: values } });
  };

  const goPublishMessage = () => {
    navigate('/publish-message');
  };

  const goUserInfo = () => {
    navigate('/user-info');
  };

  const goLogin = () => {
    navigate('/login');
  }

  const goRegister = () => {
    navigate('/login', { state: { type: '注册' } });
  }

  return (
    <div className='container-home'>
      <header>
        <div className='head-top'>
          <img src={require('../../assets/logo.png')} alt=""  />
          <div>
            <div className='search'> 
              <Input placeholder='请输入关键字' bordered={false} onPressEnter={handleSearch} />
              <Search theme="outline" size="14" fill="#333" strokeWidth={2}/>
            </div>
            <div>
              <EditTwo theme="outline" size="16" fill="#fff" strokeWidth={3}/>
              <span onClick={goPublishMessage}>发布消息</span>
            </div>
            <div className='loginInfo'>
              {
                JSON.stringify(userInfo) != "{}" 
                ? <div className='info' onClick={goUserInfo}>
                    {userInfo.username}
                  </div>
                : <div className='unLogin'>
                    <div onClick={goLogin}>登录</div>
                    <div onClick={goRegister}>注册</div>
                  </div>
              }
            </div>
          </div>
        </div>

        <div className='head-btm'>
          <Tabs 
            defaultActiveKey="1" 
            items={tabsList} 
            onChange={onTabChange} 
            indicatorSize={20}
          />
        </div>
      </header>
      <section onScroll={handleScroll}>
        {
          tabKey == 1 
          ? <div className='sect-box'>
              <div className='sectBox-banner'>
                <img src={require('../../assets/banner.png')} alt="" />
              </div>
              <div className='sectBox-content'>
                <div className='sect-list'>
                  {/* <div className='sect-list-head'>
                    <img src={require('../../assets/star-left.png')} alt="" />
                    <span>创意水杯</span>
                    <img src={require('../../assets/star-right.png')} alt="" />
                  </div> */}
                  <div className='sect-list-goods'>
                    {
                      goodsList?.map((item, key) => {
                       return (
                        <div className='goods-item' key={key}>
                          <img src={item.thumbnail} alt="" />
                          <div className='goods-name'>{item.name}</div>
                          <div className='goods-info'>
                            <div className='price'>￥<span>{item.minPrice}</span>起</div>
                            <div onClick={() => { goGoodsDetail(item) }}>查看</div>
                          </div>
                        </div>
                       ) 
                      })
                    }
                  </div>

                </div>
              </div>
            </div>
          : <div className='sect-goodsList'>
              <div className='goodsList'>
                {
                  goodsList?.map((item, key) => {
                    return (
                      <div className='goodsList-item' key={key}>
                        <img src={item.thumbnail} alt="" />
                        <div className='goodsList-item-content'>
                          <div className='goods-name'>{item.name}</div>
                          <div className='price'>￥<span>{item.minPrice}</span>起</div>
                          <div className='goods-coordinate'>
                            <Local theme="outline" size="16" fill="#999" strokeWidth={2}/>
                            <span>{item.business}</span>
                          </div>
                          <div className='goods-time'>更新：{item.updateTime}</div>
                          <div className='goods-look'>
                            <div onClick={() => { goGoodsDetail(item) }}>查看</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
        }
        {
          total === goodsList.length &&
          <div className='filing-number'  >
            <div>Copyright @ 2022-2023 杭州倪王科技有限公司 All Right Reserved</div>
            <div>浙ICP备2023008165号-3</div>
          </div>
        }
        
      </section>
      
    </div>
  )
};

export default Home