import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import { Button, Input, Form, message } from 'antd';
import { LockOutlined, UserOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { login, register } from '../../service/api';

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [isRegister, setIsRegister] = useState(false);

  useEffect(() => {
    const { type } = location?.state || '';
    if(type == '注册') {
      setIsRegister(true);
    }
  }, [])

  //登录
  const onLogin = (values) => {
    login({...values}).then(res => {
      if(res.isSuccess && res.code == 200) {
        message.success('登陆成功');
        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('userInfo', JSON.stringify(res.data));
        navigate('/');
      } else {
       message.error(res.msg);
      }
    }).catch(err => {
      console.log('err:', err);
    });
  }

  //去注册
  const goRegister = () => {
    setIsRegister(!isRegister);
  }

  //注册
  const onRegister = (values) => {
    register({...values}).then(res => {
      if(res.isSuccess && res.code == 200) {
        message.success('登陆成功');
        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('userInfo', JSON.stringify(res.data));
        navigate('/');
      } else {
       message.error(res.msg);
      }
    }).catch(err => {
      console.log('err:', err);
    });
  }

  return (

    <div className='container-login'>
      <img src={require("../../assets/logo-bgImg.png")} mode="widthFix|aspectFill" />

      <div className='login-logo'>
        <img src={require("../../assets/logo.png")} mode="widthFix|aspectFill" />
        <div>hi，欢迎来到杭州倪王科技有限公司！</div>
      </div>
      <div className='login-form' >
          <div className='login-info'>

            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={isRegister ? onRegister : onLogin}
            >
              <Form.Item
                name="account"
                rules={[{ required: true, message: '请输入账号!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入账号" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="请输入密码"
                />
              </Form.Item>

              {
                isRegister &&
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: '请输入昵称!' }]}
                >
                  <Input prefix={<UsergroupDeleteOutlined />} placeholder="请输入昵称" />
                </Form.Item>
              }

              <div className='login-btns'>
                <Button type="primary" htmlType="submit" className='loginBtn' >
                  { isRegister ? '注册' : '登录' }
                </Button>
                { isRegister ? <div onClick={goRegister} >登录</div> : <div onClick={goRegister} >注册</div> }
              </div>
            </Form>
          </div>
      </div>
      <div className='filing-number login-filing-number'  >
        <div>Copyright @ 2022-2023 杭州倪王科技有限公司 All Right Reserved</div>
        <div>浙ICP备2023008165号-3</div>
      </div>
    </div>
  )
}

export default Login  