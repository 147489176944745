import {
  get,
  post,
  postJson,
} from "./request";
 
const devBaseUrl = 'https://youpindou.com:8084/ipcEdi'; //http://192.168.1.200:8084 
const proBaseUrl = 'https://youpindou.com:8084/ipcEdi'; //https://youpindou.com:8084/ipcEdi

export const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseUrl : proBaseUrl;
export const TIMEOUT = 10000;

// 登录
export const login = (param) =>
	postJson(`${BASE_URL}/user/login`, param);

// 注册
export const register = (param) =>
	postJson(`${BASE_URL}/user/register`, param);

// 修改信息
export const user_update = (param) =>
	postJson(`${BASE_URL}/user/update`, param);

// 商品分页
export const product_findByPage = (param) =>
  postJson(`${BASE_URL}/product/findByPage`, param);

// 发布商品
export const product_publish = (param) =>
  postJson(`${BASE_URL}/product/publish`, param);
