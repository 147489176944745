import React from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';

// 导入所需组件
import Home from '../pages/home' // 首页
import GoodsDetail from '../pages/goods-detail' // 商品详情
import PublishMessage from '../pages/publish-message' // 发布消息
import UserInfo from '../pages/userInfo' // 个人信息

import Login from '../pages/login' //登录
import Eorr404 from '../pages/Eorr404'; // 404

const RouterView = () => {

  return (
    <HashRouter >
      <Routes>
        {/** *菜单 */}
        <Route element={<Home />} path='/'></Route>
        <Route element={<GoodsDetail />} path='/goods-detail'></Route>
        <Route element={<PublishMessage />} path='/publish-message'></Route>
        <Route element={<UserInfo />} path='/user-info'></Route>

        <Route path="*" element={<Eorr404 />} />
        <Route element={<Login />} path='/login'></Route>
      </Routes>
    </HashRouter>
    
  );
}

export default RouterView;