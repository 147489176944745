import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import './index.css';
import { Input, Menu, Form, Button, Tabs, message, Radio } from 'antd';
import { User, EditTwo } from '@icon-park/react';
import { user_update, product_findByPage } from '../../service/api';
import _ from 'loadsh';

const tabsList = [
  {
    key: 2,
    label: '创意水杯',
  },
  {
    key: 3,
    label: '服装鞋包',
  },
  {
    key: 4,
    label: '家居百货',
  },
  {
    key: 5,
    label: '家居家纺',
  },
  {
    key: 6,
    label: '美妆个护',
  },
  {
    key: 7,
    label: '日化清洁',
  },
  {
    key: 8,
    label: '数码家电',
  },
  {
    key: 9,
    label: '文玩办公',
  },
  {
    key: 10,
    label: '饰品配件',
  },
  {
    key: 11,
    label: '母婴亲子',
  },
]

const UserInfo = () => {

  const [menuKey, setMenuKey] = useState('1');
  const [tabKey, setTabKey] = useState(2);
  const [userInfo, setUserInfo] = useState({});
  const [userName, setUserName] = useState('');

  const [pageCurrent, setPageCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [goodsList, setGoodsList] = useState([]);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('userInfo')) || '';
    setUserInfo(user);
    setUserName(user.userName);
    form.setFieldsValue(user);
  }, []);

  useEffect(() => {
    if(menuKey == 2) {
      getGoodsList();
    }
  }, [pageCurrent, tabKey, menuKey]);

  //获取商品列表
  const getGoodsList = () => {
    let user = JSON.parse(sessionStorage.getItem('userInfo')) || '';

    const param = {
      page: { current: pageCurrent, size: 30 },
      query: { userId: user.id, type: tabKey }
    };

    product_findByPage(param).then(res => {
      if(res.code ==200 && res.isSuccess) {
        const { records, total } = res.data;
        if(records.length > 0) {
          if(pageCurrent > 1) {
            let list =  _.uniqWith([...goodsList, ...records], _.isEqual);
            setGoodsList(list);
          } else {
            setGoodsList(records);
          }
        }
        if(pageCurrent == 1 && records.length == 0) {
          setGoodsList([]);
        }
        setTotal(total);
      }
    });
  };

  // 上拉加载
  const handleScroll = (e) => {
    let current = pageCurrent;
    if(e.target.scrollTop > 200) {
      sessionStorage.setItem('scrollTop', e.target.scrollTop);
    }
    
    //判断滑动到了底部
    if (e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <= 10) {
      //判断是否还需调用接口
      if (goodsList.length != total) {
        setPageCurrent(current+1);
      }
    }
  };

  function getItem(label, key, icon, children) {
    return { key, icon, children, label };
  };

  const items = [
    getItem('个人信息', '1', <User theme="outline" size="16" strokeWidth={2}/>),
    getItem('个人发布', '2', <EditTwo theme="outline" size="16" strokeWidth={3}/>),
  ];

  const onMenuChange = (e) => {
    setMenuKey(e.key);
  }

  const onTabChange = (key) => {
    setTabKey(key);
    setPageCurrent(1);
  };

  // 提交
  const onFinish = (values) => {
    user_update({ ...values, userId: userInfo.id }).then(res => {
      if(res.code ==200 && res.isSuccess) {
        message.success('修改成功');
        userInfo.username = values.username;
        userInfo.gender = values.gender;
        setUserName(values.username);
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      } else {
        message.error(res.msg);
      }
    });
  };

  const goGoodsDetail = (values) => {
    navigate('/goods-detail', { state: { details: values } });
  };

  const goHome = () => {
    navigate('/');
  };

  const onExit = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  return (
    <div className='container-userInfo'>
      <header>
        <div>
          <img src={require('../../assets/logo.png')} alt="" onClick={goHome} />
          <div>个人信息</div>
        </div>
        <div>
          <div>{userInfo.username}</div>
          <div onClick={onExit}>退出</div>
        </div>
      </header>
      <section>
        <div className='sect-left'>
          <div className='user-content'>
            <img src={require('../../assets/head-sculpture.png')} alt="" />
            <div>{userInfo.username}</div>
          </div>
          <Menu
            defaultSelectedKeys={[menuKey]}
            style={{ width: '100%' }}
            items={items}
            onSelect={onMenuChange}
          />
        </div>
        <div className='sect-right'>
          {
            menuKey == 1 
            ? <div className='user-form'>
                <div className='user-form-avater'>
                  <img src={require('../../assets/head-sculpture.png')} alt="" />
                  <div>头像</div>
                </div>
                <Form
                  name="form-user"
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  form={form}
                >
                  <Form.Item name="username" label="昵称:">
                    <Input placeholder='请输入昵称' />
                  </Form.Item>

                  <Form.Item name="gender" label="性别:">
                  <Radio.Group >
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                  </Radio.Group>
                  </Form.Item>
                  <Button type="primary" htmlType="submit"> 保存 </Button>
                </Form>
              </div>
            : <div className='release-list'>
                <div className='release-tabs'>
                  <Tabs defaultActiveKey="2" items={tabsList} onChange={onTabChange} indicatorSize={20} />
                </div>
                <div className='release-goodsList'>

                  {
                    goodsList?.map((item, key) => {
                      return (
                      <div className='goods-item' key={key}>
                        <img src={item.thumbnail} alt="" />
                        <div className='goods-name'>{item.name}</div>
                        <div className='goods-info'>
                          <div className='price'>￥<span>{item.minPrice}</span>起</div>
                          <div onClick={() => { goGoodsDetail(item) }}>查看</div>
                        </div>
                      </div>
                      ) 
                    })
                  }

                </div>
              </div>
          }
        </div>
        
      </section>
      <div className='filing-number'  >
        <div>Copyright @ 2022-2023 杭州倪王科技有限公司 All Right Reserved</div>
        <div>浙ICP备2023008165号-3</div>
      </div>
    </div>
  )
};

function AuthRouter() {
  // 获取token
  let token = sessionStorage.getItem('token') || '';
  // 判断token是否存在 存在直接渲染主页面
  if (token) {
      return <UserInfo />
  } else {
      return <Navigate to={'/login'}></Navigate>  //没有token跳转登录页面
  }
}

export default AuthRouter;