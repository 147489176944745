import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../home/index.css';
import { Input, Tabs } from 'antd';
import { Search, EditTwo, PhoneTelephone } from '@icon-park/react';

const tabsList = [
  {
    key: 1,
    label: '首页',
  },
  {
    key: 2,
    label: '创意水杯',
  },
  {
    key: 3,
    label: '服装鞋包',
  },
  {
    key: 4,
    label: '家居百货',
  },
  {
    key: 5,
    label: '家居家纺',
  },
  {
    key: 6,
    label: '美妆个护',
  },
  {
    key: 7,
    label: '日化清洁',
  },
  {
    key: 8,
    label: '数码家电',
  },
  {
    key: 9,
    label: '文玩办公',
  },
  {
    key: 10,
    label: '饰品配件',
  },
  {
    key: 11,
    label: '母婴亲子',
  },
]

const GoodsDetail = () => {

  const [userInfo, setUserInfo] = useState({});
  const [goodsDetail, setGoodsDetails] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('userInfo')) || '';
    setUserInfo(user);

    let { details } = location.state || {};
    details.detail = details.detail ? details.detail.toString().split(',') : [];
    setGoodsDetails(details);
  }, [goodsDetail]);

  const onTabChange = (key) => {
    navigate('/');
  };

  const onTabClick = () => {
    navigate('/');
  };

  const goPublishMessage = () => {
    navigate('/publish-message');
  };

  const goUserInfo = () => {
    navigate('/user-info');
  };

  const goLogin = () => {
    navigate('/login');
  }

  const goRegister = () => {
    navigate('/login', { state: { type: '注册' } });
  }

  //搜索
  const handleSearch = () => {
    navigate('/');
  };

  return (
    <div className='container-home'>
      <header>
        <div className='head-top'>
          <img src={require('../../assets/logo.png')} alt=""  />
          <div>
            <div className='search'> 
              <Input placeholder='请输入关键字' bordered={false} onFocus={handleSearch} />
              <Search theme="outline" size="14" fill="#333" strokeWidth={2}/>
            </div>
            <div>
              <EditTwo theme="outline" size="16" fill="#fff" strokeWidth={3}/>
              <span onClick={goPublishMessage}>发布消息</span>
            </div>
            <div className='loginInfo'>
              {
                JSON.stringify(userInfo) != "{}" 
                ? <div className='info' onClick={goUserInfo}>
                    {userInfo.username}
                  </div>
                : <div className='unLogin'>
                    <div onClick={goLogin}>登录</div>
                    <div onClick={goRegister}>注册</div>
                  </div>
              }
            </div>
          </div>
        </div>

        <div className='head-btm'>
          <Tabs
            defaultActiveKey="1"
            items={tabsList}
            onChange={onTabChange}
            onTabClick={onTabClick}
            indicatorSize={20}
          />
        </div>
      </header>
      <section>
        <div className='goods-detail'>

          <div className='detail-card'>
            <img src={goodsDetail.thumbnail} alt="" />
            <div className='card-content'>
              <div className='card-name'>{goodsDetail.name}</div>
              <div className='price'>￥<span>{goodsDetail.minPrice}</span>起</div>
              <div className='card-coordinate'>
                <span>商家:</span>
                <span>{goodsDetail.business}</span>
              </div>
              <div className='card-userInfo'>
                <div>联系人: {goodsDetail.contacts}</div>
                <div className='phone'>
                  <PhoneTelephone theme="filled" size="16" fill="#EA5F5F" strokeWidth={2} />
                  <span>电话: {goodsDetail.phone}</span>
                </div>
              </div>
              <div></div>
            </div>
          </div>

          <div className='detail-info'>
            <div className='detailInfo-left'>
              <header>
                <img src={require('../../assets/star-left.png')} alt="" />
                <span>产品介绍</span>
                <img src={require('../../assets/star-right.png')} alt="" />
              </header>
              <div style={{ marginBottom: '24px' }}>
                {
                  goodsDetail?.detail?.map((item, key) => {
                    return <img key={key} src={item} alt="" />
                  })
                }
              </div>
              <div className='filing-number'>
                <div>Copyright @ 2022-2023 杭州倪王科技有限公司 All Right Reserved</div>
                <div>浙ICP备2023008165号-3</div>
              </div>
            </div>

            {/* <div className='detailInfo-right'>
              <header>
                <img src={require('../../assets/star-left.png')} alt="" />
                <span>智能推荐</span>
                <img src={require('../../assets/star-right.png')} alt="" />
              </header>

              <div className='detailInfo-goods'>
                <img src={require('../../assets/banner.png')} alt="" />
                <div>122骨晴雨伞两用自动伞抗风骨晴雨伞两用自动伞抗风加动伞抗风骨晴动伞</div>
              </div>
              <div className='detailInfo-goods'>
                <img src={require('../../assets/banner.png')} alt="" />
                <div>122骨晴雨伞两用自动伞抗风骨晴雨伞两用自动伞抗风加动伞抗风骨晴动伞</div>
              </div>
            </div> */}
          </div>

        </div>
      </section>
      
    </div>
  )
};

export default GoodsDetail;

