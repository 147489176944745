import { PlusOutlined } from '@ant-design/icons';
import { Upload, message, Modal } from 'antd';
import { useState } from 'react';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
  if (!isJpgOrPng) {
    message.error('只能上传 JPG/PNG 的图片!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('图片大小不能大于2MB!');
  // } && isLt2M
  return isJpgOrPng;
};
const UploadImg = (props) => {
  const { fileList, handleChange, handleCancel, handlePreview, previewOpen, previewImage, onRemove } = props;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }} >
        上传图片
      </div>
    </div>
  );

  return (
    <>
      <Upload
        listType="picture-card"
        className="avatar-uploader"
        action="https://youpindou.com:8084/ipcEdi/image/upload"
        method="post"
        name='file'
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        multiple={true}
        onRemove={onRemove}
      >
        {fileList.length >= 20 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default UploadImg;